.Photos {
    margin-top: 0; padding: 0 0;
    .PhotosHeader {
        background-color: $white;
        p {
            color: $black;
        }
    }
    .card {
      position: relative;
      max-width: calc( calc( 100% - 2rem ) / 2 );
      min-height: 35rem;
      cursor: pointer;
      overflow: hidden;
      vertical-align: middle;
      box-shadow: 0 .3rem .6rem #00000029;
      border-radius: .4rem;
      .defi {
        position: absolute;
        top: 0; right: 0; left: 0;
        margin: 0; padding: .65rem .5rem;
        background-color: $white;
        font-size: .9rem;
        box-shadow: 0 .3rem .6rem #00000029;
        color: $black;
      }
      .photo {
        vertical-align: middle;
      }
      @media (min-width: 767px) {
        max-width: calc( calc( 100% - 4rem ) / 4 );
      }
      @media (min-width: 1024px) {
        max-width: calc( calc( 100% - 5rem ) / 5 );
      }
      @media (min-width: 1280px) {
        max-width: calc( calc( 100% - 6rem ) / 6 );
      }
    }
    .hide {
      position: absolute;
      top: -100vh; right: -100vw; 
      width: 0;
      height: 0;
      .PhotoCameraClose {
        display: none;
      }
      .photo {
        top: -100vh; right: -100vw; 
        transition: top .2s linear, left .2s linear;
        overflow: hidden;
      }
    }
    .fullscreen {
      position: fixed;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: 10;
      width: 100vw;
      height: 100vh;
      background-color: rgba( $black, .95 );
      cursor: pointer;
      overflow: hidden;
      .PhotoCameraClose {
        display: block;
      }
      .photo {
        position: absolute; width: 100%; max-width: 90vw; max-height: 100vh;
        top: 50%; left: 50%;
        transform: translate( -50%, -50% );
        transition: top .4s linear, left .4s linear;
        .photo-content {
          position: relative;
          display: inline-block;
          width: fit-content;
          text-align: center;
          border-radius: .4rem;
          overflow: hidden;
        }
        p {
          margin-top: 0; margin-bottom: 0; padding: 1rem 0;
          background-color: $white;
          box-shadow: 0 .3rem .6rem #00000029;
          color: $black;
        }
        img {
          max-width: 90vw; max-height: calc( 100vh - 4.5rem );
        }
      }
    }
}

