.PhotoAdd {
  position: fixed;
  bottom: 10%; left: 50%;
  transform: translateX(-50%);
  padding: 0;
}
  
.PhotoAddError {
  position: fixed;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  width: 75vw;
  z-index: 100;
}

.PhotoUploadTitle {
  position: absolute;
  top: 0; right: 0; left: 0;
  margin: 0; padding: 1rem 3rem; 
  background-color: $white;
  color: $black;
}

.PhotoUploadForm {
  position: fixed;
  top: 50%;
  left: 1rem; right: 1rem;
  transform: translateY(-50%);
  .form-control {
    font-size: 1.2rem;
  }
}