.btn {
    border-radius: .4rem; margin: 0 0 2rem 0; padding: 2rem 0; width: 100%; max-width: 94vw;
    text-align: center; font-size: 1.6rem; font-weight: $fw-bold;
}

.btn-primary {
    background-color: $yellow; border-color: $yellow;
    color: $white;
    text-transform: uppercase;
}

.btn-secondary {
    background-color: transparent; border-color: transparent;
    color: $yellow; text-decoration: underline;
}