.Home {
	width: 100%; min-height: calc( 100vh - 5.5rem );
}

.Guests {
	width: 100%;
	margin: 0; padding: 0;
	.list-group {
		margin: 0; padding: 0;
	}
}
.QrCode {
	canvas {
		width: 100%; max-width: 512px;
	}
}