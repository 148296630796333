
.react-html5-camera-photo {
    max-height: 100vh;
  }
  #container-circles {
    position: fixed !important;
    bottom: 15% !important;
    #outer-circle {
      left: 50%; transform: translateX(-50%);
      top: -4rem;
      height: 10rem; width: 10rem;
      background-color: transparent; border: .4rem solid $white;
      #inner-circle {
        top: 2.8rem;
        height: 8rem; width: 8rem; margin-left: -4rem;
        background-color: $white;
      }
    }
  }
  

.PhotoCameraClose {
    position: absolute;
    top: 1.6rem; right: 1rem;
    z-index: 10;
    font-size: 2.5rem !important; 
    color: $black !important;
}
  
.PhotoCameraDefiChoiceTitle {
    position: absolute;
    top: 0; right: 0; left: 0;
    margin: 0; padding: 1rem 3rem; 
    background-color: $white;
    z-index: 10;
    font-size: 1.7rem;
    color: $black;
}

.PhotoCameraUpload {
    position: fixed;
    bottom: 5.5rem; left: 2.5rem;
    background-color: $black; padding: 1.3rem; border-radius: 100%;
    z-index: 10;
}

.PhotoCameraChange {
    transform: rotate(0deg);
    position: fixed;
    bottom: 5.5rem; right: 2.5rem;
    background-color: $white; padding: 1.3rem; border-radius: 100%;
    z-index: 10;
    cursor: pointer;
    transition: transform .25s linear !important;
    &:hover {
      transform: rotate(180deg);
    }
}