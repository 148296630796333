.DefisList {
    padding-top: 2.5rem;
    z-index: 10;
    background-color: transparent;

    .DefisListLogo {
      max-width: 11rem;
    }

    .DefisListIntro {
      margin: 0; padding: 0;
      font-size: 1.2rem;
    }

    .DefisListTitle {
      font-size: 2.3rem;
      color: $white; font-weight: $fw-regular; text-align: center;
      &:after {
        content: '';
        display: inline-block;
        margin: 1rem 5rem .5rem 5rem; padding: 0; width: 7.5rem; height: .1rem;
        color: $white; background-color: rgba( $white, .5 );
      }
    }

    .DefisListAction {
      margin: 0; padding: 0;
      font-size: 1.7rem;
    }
 
    .DefisListContent  {
      margin: 2.5rem .5rem 10rem .5rem; padding: 0;
      list-style: none; text-align: left;
      background-color: transparent;
    }
    li {
      margin: 0 0 .5rem 0; padding: 1rem 1rem;
      background-color: $white;
      color: $black; font-size: 1.3rem;
      p {
        position: relative;
        display: inline-block; width: 100%;
        margin: 0; padding: 0 3rem 0 0;
        color: $black;
      }
      &.checked {
        background-color: $yellow;
        p {
          font-weight: $fw-bold;
          color: $white;
        }
      }
      span {
        position: absolute; top: 50%; right: .5rem;
        transform: translateY(-50%);
      }
    }

    .DefisButton {
      position: fixed;
      bottom: 0; right: 1rem; left: 1rem;
    }
}