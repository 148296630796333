.PhotoPreview {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: transparent linear-gradient(180deg, $grey 0%, $black 100%) 0% 0% no-repeat padding-box;
    .PhotoPreviewImg {
        position: absolute;
        top: 0; right: 5rem; left: 5rem;
        height: 75vh;
        &:after {
            content: '';
            position: absolute; bottom: 0; right: 0;
            display: inline-block; width: 10rem; height: 10rem;
            background-image: url('../../../assets/img/BlondeEtBarbu.png'); background-repeat: no-repeat; background-position: center center; background-size: contain;
        }
        img {
            position: absolute;
            top: 50%; left: 50%;
            transform: translate( -50%, -50% ) matrix(1, 0.07, -0.07, 1, 0, 0);
            transition: top .4s linear, left .4s linear;
        }
    }
}


.PhotoPreviewControls {
    position: fixed;
    right: 0; bottom: 2.5%; left: 0;
    text-align: center;
    height: 20vh;
    button {
        margin-bottom: 0;
    }
    .PhotoLoading {
        display: inline-block;
    }
}